import {
  type States,
  getHealthInsurances,
  getSpecialties,
  getStatesAndCities,
} from "@/app/data/data";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export async function getSearchDropdownContent(): Promise<{
  healthInsurancesList: { value: string; label: string }[];
  specialtiesList: { value: string; label: string }[];
  statesAndCities: States[];
}> {
  const healthInsurances = await getHealthInsurances();
  const healthInsurancesList = healthInsurances.map((item) => ({
    value: item.name,
    label: item.name
      .replace(/\b\w/g, (char) => {
        return char.toLocaleUpperCase();
      })
      .replace(/\B\w/g, (char) => {
        return char.toLocaleLowerCase();
      }),
  }));
  const specialties = await getSpecialties();
  const specialtiesList = specialties.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const statesAndCities = await getStatesAndCities();
  return { healthInsurancesList, specialtiesList, statesAndCities };
}
